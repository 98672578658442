<template>
    <v-form ref="form">
        <v-card class="card-shadow">
            <div class="card-title-wrapper pa-6">
                <v-row>
                    <v-col cols="12">
                        <v-card-title class="text-h1 font-weight-600 pa-0 mb-4">Registered Organisation</v-card-title>
                        <v-card-subtitle class="text-subtitle-1 pa-0">The organisation’s information is important to establish clear brand guidelines for forms and documents. </v-card-subtitle>
                    </v-col>
                </v-row>
            </div>
            <v-card-text v-if="configs.length" class="pa-6">
                <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="5" class="d-flex">
                        <Uploader
                            @onRemove="saveConfigOnImageRemoval"
                            v-model="configs[0].organisation.logo"
                            width="200px"
                            :accepts="['image/png', 'image/jpeg', 'image/jpg', 'image/webp']"
                            :maxSize="4194304"
                            :context="'logo'" />
                        <div class="d-flex flex-grow-1 flex-column ml-8">
                            <div>
                                <label for="orgName" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Organisation Name</label>
                                <v-text-field
                                    :rules="[rules.required]"
                                    id="orgName"
                                    autocomplete="off"
                                    hide-details="auto"
                                    validate-on-blur
                                    v-model="configs[0].organisation.name"
                                    outlined
                                    class="input-style font-size-input text-color-gray mt-0 mb-6"
                                    placeholder="Company or department">
                                </v-text-field>
                            </div>
                            <div>
                                <label for="orgRegistrationNum" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Registration Number (Optional)</label>
                                <v-text-field
                                    id="orgRegistrationNum"
                                    v-model="configs[0].organisation.regNum"
                                    hide-details="auto"
                                    outlined
                                    class="input-style font-size-input text-color-gray mt-0 mb-6"
                                    placeholder="YYYY/NNNNNN/NN">
                                </v-text-field>
                            </div>
                            <div class="hide-large">
                              <label for="orgColour" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Accent Colour</label>
                              <v-text-field
                                  :rules="[rules.required]"
                                  v-model="colorValue"
                                  validate-on-blur
                                  id="orgColour"
                                  hide-details="auto"
                                  outlined
                                  class="input-style font-size-input text-color-gray mt-0 mb-6 "
                                  placeholder="#805CFE">
                                  <template v-slot:append>
                                      <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                          <template v-slot:activator="{ on }">
                                              <div :style="swatchStyle" v-on="on" />
                                          </template>
                                          <v-card>
                                              <v-card-text class="pa-0">
                                                  <v-color-picker v-model="colorValue" flat />
                                              </v-card-text>
                                          </v-card>
                                      </v-menu>
                                  </template>
                              </v-text-field>
                            </div>
                            <v-btn @click="saveOrganisation()" elevation="0" height="43" class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm save-btn" color="#6F70FF">
                                Update Organisation
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col class="hide-tablet" cols="12" sm="12" md="6" lg="5" xl="5">
                        <label for="orgColour" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Accent Colour</label>
                        <v-text-field
                            :rules="[rules.required]"
                            v-model="colorValue"
                            validate-on-blur
                            id="orgColour"
                            hide-details="auto"
                            outlined
                            class="input-style font-size-input text-color-gray mt-0 mb-6"
                            placeholder="#805CFE">
                            <template v-slot:append>
                                <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                    <template v-slot:activator="{ on }">
                                        <div :style="swatchStyle" v-on="on" />
                                    </template>
                                    <v-card>
                                        <v-card-text class="pa-0">
                                            <v-color-picker v-model="colorValue" flat />
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { mapActions, mapGetters } from 'vuex';
import Uploader from '@/components/UploaderV2/Uploader.vue';

export default {
    components: { Uploader },
    data() {
        return {
            menu: false,
            rules: {
                required: (value) => (value && Boolean(value)) || '*This field is required'
            }
        };
    },
    mixins: [makeFindMixin({ service: 'configs' }), makeFindMixin({ service: 'uploads', watch: true })],
    methods: {
        changeColour(value) {
            this.configs[0].organisation.accentColour = value;
        },
        async saveOrganisation() {
            if (this.$refs.form.validate()) {
                try {
                    await this.configs[0].save();
                    this.showSuccess('Organisation Information successfully saved!');
                } catch (error) {
                    this.showError(error);
                }
            }
        },
        async saveConfigOnImageRemoval() {
            if (this.$refs.form.validate()) {
                try {
                    await this.configs[0].save();
                } catch (error) {
                    this.showError(error);
                }
            }
        },
        ...mapActions('app', ['showSuccess', 'showError']),
        ...mapActions('uploads', { removeUploads: 'remove' })
    },
    computed: {
        ...mapGetters('apiAuth', { authUser: 'user' }),
        configsParams() {
            return {
                query: {
                    $sort: { id: 1 }
                }
            };
        },
        uploadsParams() {
            if (!this.authUser) return;
            return {
                query: {
                    userId: this.authUser.id,
                    context: 'logo'
                }
            };
        },
        swatchStyle() {
            return {
                backgroundColor: this.colorValue,
                cursor: 'pointer',
                height: '15px',
                width: '15px',
                borderRadius: '100%',
                transition: 'border-radius 200ms ease-in-out'
            };
        },
        colorValue: {
            get() {
                if (!this.configs[0].organisation.accentColour) {
                    this.changeColour('#6F70FF');

                    return '#6F70FF';
                } else {
                    return this.configs[0].organisation.accentColour;
                }
            },
            set(value) {
                this.changeColour(value);
            }
        }
    }
};
</script>
<style scoped lang="scss">
.image-upload {
    width: 175px !important;
    height: 175px !important;
}

.save-btn {
    width: 170px !important;
}

@media screen and (max-width: 960px) {
    .hide-tablet {
        display: none;
    }
}

@media screen and (min-width: 961px) {
    .hide-large {
        display: none;
    }
}
</style>
